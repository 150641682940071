export const UddannelserList = [
    {
        "uddannelse": "3D digital artist/game artist (Truemax Academy)",
        "id": "1"
    },
    {
        "uddannelse": "Administrationsøkonom",
        "id": "2"
    },
    {
        "uddannelse": "Afspændingspædagog (psykomotorisk terapeut)",
        "id": "3"
    },
    {
        "uddannelse": "Alkolog (DAC-uddannelsen)",
        "id": "4"
    },
    {
        "uddannelse": "Arkitekt (KADK og Arkitektskolen Århus)",
        "id": "5"
    },
    {
        "uddannelse": "Autoteknolog",
        "id": "6"
    },
    {
        "uddannelse": "Bandagist",
        "id": "7"
    },
    {
        "uddannelse": "Beklædningsdesigner (Københavns Mode- og Designskole og Margretheskolen)",
        "id": "8"
    },
    {
        "uddannelse": "Bevægelsespædagog (Gotvedskolen)",
        "id": "9"
    },
    {
        "uddannelse": "Bibliotekar",
        "id": "10"
    },
    {
        "uddannelse": "Bibliotekar, overbygning",
        "id": "11"
    },
    {
        "uddannelse": "Billedjournalist",
        "id": "12"
    },
    {
        "uddannelse": "Billedkunst ved kunstakademierne",
        "id": "13"
    },
    {
        "uddannelse": "Bioanalytiker (hospitalslaborant)",
        "id": "14"
    },
    {
        "uddannelse": "Byggetekniker",
        "id": "15"
    },
    {
        "uddannelse": "Bygningskonstruktør",
        "id": "16"
    },
    {
        "uddannelse": "Cand.pæd. (Danmarks Pædagogiske Universitet)",
        "id": "17"
    },
    {
        "uddannelse": "Civilingeniør",
        "id": "18"
    },
    {
        "uddannelse": "DAC Uddannelsen",
        "id": "19"
    },
    {
        "uddannelse": "Datamatiker",
        "id": "20"
    },
    {
        "uddannelse": "Designer (KADK og Designskolen Kolding)",
        "id": "21"
    },
    {
        "uddannelse": "Designteknolog",
        "id": "22"
    },
    {
        "uddannelse": "Diakon/sognemedhjælper (Kirkefaglig Videreuddannelse)",
        "id": "23"
    },
    {
        "uddannelse": "Diplomingeniør",
        "id": "24"
    },
    {
        "uddannelse": "E-designer",
        "id": "25"
    },
    {
        "uddannelse": "Eksportingeniør",
        "id": "26"
    },
    {
        "uddannelse": "Ergoterapeut",
        "id": "27"
    },
    {
        "uddannelse": "Ergoterapeut, videreuddannelse",
        "id": "28"
    },
    {
        "uddannelse": "Erhvervssproglige uddannelser på handelshøjskolerne, bachelor- og kandidatuddannelser",
        "id": "29"
    },
    {
        "uddannelse": "Erhvervsøkonomiske uddannelser på handelshøjskolerne, bachelor- og kandidatuddannelser",
        "id": "30"
    },
    {
        "uddannelse": "Ernæring og sundhed",
        "id": "31"
    },
    {
        "uddannelse": "Exam. pæd. (Danmarks Pædagogiske Universitet)",
        "id": "32"
    },
    {
        "uddannelse": "Farmaceut",
        "id": "33"
    },
    {
        "uddannelse": "Filmskoleuddannelser (Den Danske Filmskole)",
        "id": "34"
    },
    {
        "uddannelse": "Finansøkonom",
        "id": "35"
    },
    {
        "uddannelse": "Fiskeriteknolog",
        "id": "36"
    },
    {
        "uddannelse": "Fiskeskipper af 1. grad",
        "id": "37"
    },
    {
        "uddannelse": "Forfatter (Forfatterskolen)",
        "id": "38"
    },
    {
        "uddannelse": "Fysioterapeut",
        "id": "39"
    },
    {
        "uddannelse": "Fysioterapeut, videreuddannelse",
        "id": "40"
    },
    {
        "uddannelse": "Handelsøkonom",
        "id": "41"
    },
    {
        "uddannelse": "Hospitalslaborant, (se bioanalytiker)",
        "id": "42"
    },
    {
        "uddannelse": "Husholdningsvidenskab (Nordisk Højskole)",
        "id": "43"
    },
    {
        "uddannelse": "Idrætslærer (Paul Petersens idrætsinstitut)",
        "id": "44"
    },
    {
        "uddannelse": "Installatør",
        "id": "45"
    },
    {
        "uddannelse": "IT - og elektronikteknolog",
        "id": "46"
    },
    {
        "uddannelse": "Jordbrugsteknolog",
        "id": "47"
    },
    {
        "uddannelse": "Jordemoder",
        "id": "48"
    },
    {
        "uddannelse": "Journalist",
        "id": "49"
    },
    {
        "uddannelse": "Karakteranimation, professionsbachelor",
        "id": "50"
    },
    {
        "uddannelse": "Kirkemusikskoler (Vestervig, Løgumkloster og Sjællands Kirkemusikskole)",
        "id": "51"
    },
    {
        "uddannelse": "Klinisk tandtekniker",
        "id": "52"
    },
    {
        "uddannelse": "Konservator (KADK)",
        "id": "53"
    },
    {
        "uddannelse": "Kort- og landmålingstekniker",
        "id": "54"
    },
    {
        "uddannelse": "Kosmetolog (Kosmetologskolen CIDESCO)",
        "id": "55"
    },
    {
        "uddannelse": "Kunstformidler",
        "id": "56"
    },
    {
        "uddannelse": "Kunsthåndværker (KADK)",
        "id": "57"
    },
    {
        "uddannelse": "Laborant",
        "id": "58"
    },
    {
        "uddannelse": "Landbohøjskolens bachelor- og kandidatuddannelser",
        "id": "59"
    },
    {
        "uddannelse": "Landinspektør",
        "id": "60"
    },
    {
        "uddannelse": "Levnedsmiddelingeniør",
        "id": "61"
    },
    {
        "uddannelse": "Levnedsmiddelkandidat",
        "id": "62"
    },
    {
        "uddannelse": "Lægemiddelvidenskab, kandidatuddannelse",
        "id": "63"
    },
    {
        "uddannelse": "Lærer",
        "id": "64"
    },
    {
        "uddannelse": "Markedsføringsøkonom",
        "id": "65"
    },
    {
        "uddannelse": "Maskinmester",
        "id": "66"
    },
    {
        "uddannelse": "Miljøteknolog",
        "id": "67"
    },
    {
        "uddannelse": "Multimediedesigner",
        "id": "68"
    },
    {
        "uddannelse": "Moderne dans (Skolen for Moderne Dans i Danmark)",
        "id": "69"
    },
    {
        "uddannelse": "Musicalperformer (Det Danske Musicalakademi, Fredericia)",
        "id": "70"
    },
    {
        "uddannelse": "Musikkonservatorieuddannelserne",
        "id": "71"
    },
    {
        "uddannelse": "Pastoralseminariet",
        "id": "72"
    },
    {
        "uddannelse": "Pilot (trafikflyver)",
        "id": "73"
    },
    {
        "uddannelse": "Politiets grunduddannelse",
        "id": "74"
    },
    {
        "uddannelse": "Procesteknolog",
        "id": "75"
    },
    {
        "uddannelse": "Produktionsteknolog",
        "id": "76"
    },
    {
        "uddannelse": "Professionsbachelor i beredskab, katastrofe- og risikomanagement",
        "id": "77"
    },
    {
        "uddannelse": "Professionsbachelor i e-handel",
        "id": "78"
    },
    {
        "uddannelse": "Professionsbachelor i e-konceptudvikling",
        "id": "79"
    },
    {
        "uddannelse": "Professionsbachelor i IT-sikkerhed",
        "id": "80"
    },
    {
        "uddannelse": "Professionsbachelor i innovation og entrepreneurship",
        "id": "81"
    },
    {
        "uddannelse": "Professionsbachelor i international handel og markedsføring",
        "id": "82"
    },
    {
        "uddannelse": "Professionsbachelor i jordbrugsvirksomhed",
        "id": "83"
    },
    {
        "uddannelse": "Professionsbachelor i laboratorie-, fødevare- eller processteknologi",
        "id": "84"
    },
    {
        "uddannelse": "Professionsbachelor i offentlig administration",
        "id": "85"
    },
    {
        "uddannelse": "Professionsbachelor i softwareudvikling",
        "id": "86"
    },
    {
        "uddannelse": "Professionsbachelor i webudvikling",
        "id": "87"
    },
    {
        "uddannelse": "Professionsbachelor i økonomi og it",
        "id": "88"
    },
    {
        "uddannelse": "Projektlederuddannelse (Kaospiloterne)",
        "id": "89"
    },
    {
        "uddannelse": "Psykomotorisk terapeut",
        "id": "90"
    },
    {
        "uddannelse": "Pædagog",
        "id": "91"
    },
    {
        "uddannelse": "Radiograf",
        "id": "92"
    },
    {
        "uddannelse": "Registreret revisor, teoretisk del",
        "id": "93"
    },
    {
        "uddannelse": "Revisorkandidat",
        "id": "94"
    },
    {
        "uddannelse": "Rytmikpædagog",
        "id": "95"
    },
    {
        "uddannelse": "Sceneinstruktør/scenograf (Den Danske Scenekunstskole)",
        "id": "96"
    },
    {
        "uddannelse": "Serviceøkonom",
        "id": "97"
    },
    {
        "uddannelse": "Skibsofficer",
        "id": "98"
    },
    {
        "uddannelse": "Skov- og landskabsingeniør",
        "id": "99"
    },
    {
        "uddannelse": "Skuespiller",
        "id": "100"
    },
    {
        "uddannelse": "Socialrådgiver",
        "id": "101"
    },
    {
        "uddannelse": "Sygeplejerske, videreuddannelse ved Danmarks Sygeplejerskehøjskole",
        "id": "102"
    },
    {
        "uddannelse": "Tandlæge",
        "id": "103"
    },
    {
        "uddannelse": "Tandplejer",
        "id": "104"
    },
    {
        "uddannelse": "Teatertekniker (Den Danske Scenekunstskole)",
        "id": "105"
    },
    {
        "uddannelse": "Tegnsprogs- og mundhåndsystemtolk (Center for Tegnsprog og Tegnstøttet Kommunikation)",
        "id": "106"
    },
    {
        "uddannelse": "Tekstile fag og formidling (professionsbachelor)",
        "id": "107"
    },
    {
        "uddannelse": "Trafikflyver",
        "id": "108"
    },
    {
        "uddannelse": "Transportlogistiker",
        "id": "109"
    },
    {
        "uddannelse": "Universiteternes bachelor-, kandidat- og magisteruddannelser og faglig supplering af sidefag i forbindelse med optagelse på pædagogikum",
        "id": "110"
    }
]